<template>
  <div class="author-panel dashboard-wrap">
    <div class="row">
      <div class="col-sm-3" v-if="!isNaN(Number($store.state.user.activeAds))">
        <div class="dashboard-box dashboard-box-2">
          <div class="white-block-content">
            <img
              :aria-hidden="true"
              src="/adifier/wp-content/themes/adifier/images/dashboard/dash-2.png"
            />
            <div class="dashboard-body">
              <h2>{{ t("dashboard.submitted_ads") }}</h2>
              <p class="dash-value" aria-labelledby="dashboard-description-2">
                {{ Number($store.state.user.activeAds).toFixed(0) }}
              </p>
            </div>
            <p class="dash-footer" id="dashboard-description-2">
              {{ t("dashboard.number_of_submitted_ads") }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-sm-3"
        v-if="!isNaN(Number($store.state.user.finishedAds))"
      >
        <div class="dashboard-box dashboard-box-1">
          <div class="white-block-content">
            <img
              :aria-hidden="true"
              src="/adifier/wp-content/themes/adifier/images/dashboard/dash-1.png"
            />
            <div class="dashboard-body">
              <h2>{{ t("dashboard.finished_ads") }}</h2>
              <p class="dash-value" aria-labelledby="dashboard-description-1">
                {{ Number($store.state.user.finishedAds).toFixed(0) }}
              </p>
            </div>

            <p class="dash-footer" id="dashboard-description-1">
              {{ t("dashboard.number_of_finished_ads") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="$store.state.user.rating">
        <div class="dashboard-box dashboard-box-3">
          <div class="white-block-content">
            <img
              :aria-hidden="true"
              src="/adifier/wp-content/themes/adifier/images/dashboard/dash-3.png"
            />
            <div class="dashboard-body">
              <h2>{{ t("dashboard.your_rating") }}</h2>
              <p class="dash-value" aria-labelledby="dashboard-description-3">
                {{ Number($store.state.user.rating).toFixed(2) }} / 5.00
              </p>
            </div>
            <p class="dash-footer" id="dashboard-description-3">
              {{ t("dashboard.based_on_all_your_ads") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="!isNaN(Number($store.state.user.reviews))">
        <div class="dashboard-box dashboard-box-4">
          <div class="white-block-content">
            <img
              :aria-hidden="true"
              src="/adifier/wp-content/themes/adifier/images/dashboard/dash-4.png"
            />
            <div class="dashboard-body">
              <h2>{{ t("dashboard.reviews") }}</h2>
              <p class="dash-value" aria-labelledby="dashboard-description-4">
                {{ Number($store.state.user.reviews).toFixed(0) }}
              </p>
            </div>
            <p class="dash-footer" id="dashboard-description-4">
              {{ t("dashboard.number_of_reviews") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

export default {
  name: "UserProfile",
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t('dashboard.title')
    })

    return {
      t,
    };
  },
};
</script>

<style scoped>
.dashboard-box h2 {
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.dashboard-box .dash-value {
  font-size: 25px;
  padding: 0;
  color: black;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.dash-footer {
  font-size: 15px;
  margin: 0px;
  margin-top: 20px;
  color: #2d323e;
}
</style>
